<script setup lang="ts">
const props = defineProps({
  post: Object,
  tweet: Object,
});

const emit = defineEmits(["claimTweet"]);

const claiming = ref(false);

const claimButtonLabel = computed(() => {
  if (realItem.value?.state == "eligible-bonus") {
    let label = "Claim";
    if (realItem.value?.reward?.amount) {
      label +=
        " " +
        realItem.value?.reward?.amount +
        " " +
        realItem.value?.reward?.currency;
    }

    return label;
  }
  return "Claim";
});

const realItem = computed(() => {
  return props.post?.id
    ? {
        id: props?.post?.id,
        state: props?.post?.state,
        reward: props?.post?.reward,
      }
    : {
        id: props?.tweet?.id,
        state: props?.tweet?.rating?.state,
        reward: props?.tweet?.rating?.reward,
      };
});

function claimTweet() {
  claiming.value = true;
  apiService
    .claimTweet(realItem.value.id)
    .then(() => {
      emit("claimTweet", realItem.value);
      globalEmit("tweetClaimed", realItem.value);
      successMessage("Tweet claimed!");
    })
    .catch((e) => {
      showError(e);
    })
    .finally(() => {
      claiming.value = false;
    });
}

const claimButtonVisible = computed(() => {
  return ["eligible", "eligible-bonus"].includes(realItem.value?.state);
});
</script>

<template>
  <button
    v-if="claimButtonVisible"
    :disabled="claiming"
    @click.stop="claimTweet"
  >
    <LoadingIcon v-if="claiming" class="icon" />
    {{ claimButtonLabel }}
  </button>
</template>
