export function formatTweetText(text) {
    // Replace newlines with <br>
    const formattedText = text.replace(/\n/g, '<br>');

    // Regular expressions to match mentions, hashtags, cashtags, and URLs
    const mentionRegex = /(@\w+)/g;       // Matches @mentions
    const hashtagRegex = /#(\w+)/g;       // Matches #hashtags, capturing the word without the #
    const cashtagRegex = /\$(\w+)/g;      // Matches $cashtags, capturing the word without the $
    const urlRegex = /(https?:\/\/[^\s]+)/g; // Matches http and https URLs

    // Replace URLs with <a> tags linking to the actual URLs
    const withUrls = formattedText.replace(
        urlRegex,
        '<a href="$1" class="text-purple hover:underline" target="_blank" rel="noopener noreferrer">$1</a>'
    );

    // Replace mentions with <a> tags linking to Twitter profiles
    const withMentions = withUrls.replace(
        mentionRegex,
        '<a href="https://twitter.com/$1" class="text-purple hover:underline" target="_blank" rel="noopener noreferrer">$1</a>'
    );

    // Replace hashtags with <a> tags linking to Twitter hashtag searches, encoding #
    const withHashtags = withMentions.replace(
        hashtagRegex,
        '<a href="https://twitter.com/search?q=%23$1" class="text-purple hover:underline" target="_blank" rel="noopener noreferrer">#$1</a>'
    );

    // Replace cashtags with <a> tags linking to Twitter cashtag searches, encoding $
    const withCashtags = withHashtags.replace(
        cashtagRegex,
        '<a href="https://twitter.com/search?q=%24$1" class="text-purple hover:underline" target="_blank" rel="noopener noreferrer">&dollar;$1</a>'
    );

    return withCashtags;
}
